// Third party libs/components
import React, { useState, useRef, useEffect } from 'react';
import P from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useInView } from 'react-intersection-observer';
import { useLocation } from '@reach/router';
import axios from 'axios';
import { IP_STACK_API } from '@gatsby-local-plugin/gatsby-local-env-variables';

// Components
import Layout from '@components/layout';
import SEO from '@components/seo';
import AircraftPageOptions from '@components/AircraftPageOptions';
import AircraftGallery from '@components/AircraftGallery';
import Modal from '@components/Modal';
import AircraftQuotation from '@components/AircraftQuotation';
import AircraftDescription from '@components/AircraftDescription';
import AircraftAutonomy from '@components/AircraftAutonomy';
import AircraftSecurity from '@components/AircraftSecurity';
import NavMenu from '@components/NavMenu';
import Amenities from '@components/Amenities';
import AircraftForSaleForm from '@components/AircraftForSaleForm';
import AircraftAbout from '@components/AircraftAbout';

// Utils
import { translate as t, translate_to } from '@utils/translate';
import { dateToYear } from '@utils/dates';
import { groupBy } from '@utils/functions';
import aircraftNameHelper from '@utils/aircraftNameHelper';
import { Creators as SearchSegmentsActions } from '@store/reducers/search-segments/reducer';

const mapToImagesProp = imagesArr =>
  imagesArr.map(img => ({
    label: null,
    description: img.description,
    image: img.url,
  }));

const getAirCarrier = (airCarrier, ownerCompany) => {
  if (airCarrier && airCarrier.iconImageUrl) {
    return {
      firstRender: airCarrier.name,
      secRender: airCarrier.registration,
      thirdRender: airCarrier.hoursFlown,
      image: airCarrier.iconImageUrl,
    };
  } else {
    return {
      firstRender: ownerCompany.fantasyName,
      secRender: ownerCompany.registration,
      thirdRender: null,
      image: ownerCompany.profilePictureUrl,
    };
  }
};

const getCertificationsGroup = certifications => {
  const getExpirationDate = expDate =>
    new Date(expDate).getFullYear() - new Date().getFullYear();
  const certs = certifications.map(c => ({
    firstRender: c.certification.name,
    secRender: [
      getExpirationDate(c.expirationDate),
      dateToYear(c.certificationDate),
    ],
    expirationDate: c.expirationDate,
    thirdRender: {
      name: c.certification?.country?.name,
      iso3Code: c.certification?.country?.iso3Code,
    },
    memberSince: dateToYear(
      c.certification?.firstCertificatedAt || c.firstCertificatedAt
    ),
    image: c.certification?.iconImageUrl,
    category: c.certification?.category,
    isLive: c.certification?.isLive,
  }));
  return groupBy(certs, 'category');
};

const mapItemsAmenities = ({ amenities = [] }, lang) => {
  const TEXTS = {
    AVAIALABLE_IN: t('availableAtCharterAndShared'),
    AVAILABLE_AT_CHARTER: t('availableAtCharter'),
    AVAILABLE_AT_SHARED: t('availableAtShared'),
  };

  return amenities.map(item => {
    let description = '';
    if (
      item.availability.isAvailableForCharter === 1 &&
      item.availability.isAvailableForShared === 1
    )
      description = TEXTS.AVAIALABLE_IN;
    else if (item.availability?.isAvailableForCharter === 1)
      description = TEXTS.AVAILABLE_AT_CHARTER;
    else if (item.availability?.isAvailableForShared === 1)
      description = TEXTS.AVAILABLE_AT_SHARED;

    return {
      icon: item.iconImageUrl,
      title: item.name,
      description: description,
    };
  });
};

const mapItemsCharacteristics = (type, data) => {
  const characteristics = {
    executive: [
      'numberOfPassengers',
      'numberOfPilots',
      'numberOfLuggage',
      'luggageRackVolume',
      'cruiseSpeed',
      'maximumPayload',
      'maximumRange',
    ],
    cargo: [
      'maximumVolume',
      'maximumPayload',
      'averageLoadingTime',
      'numberOfCompartments',
      'numberOfSlots',
      'maximumRangeEmpty',
      'maximumRangeFullCapacity',
    ],
    aeromedical: [
      'numberOfPassengers',
      'numberOfPilots',
      'numberOfBeds',
      'numberOfMedicalCrew',
      'maximumPayload',
      'maximumRange',
    ],
  };

  let items = [];
  characteristics[type].forEach(item => {
    if (data[item]) items.push({ type: item, value: data[item] });
  });

  return items;
};

const mapSetupExecutive = (data, lang) => {
  const TEXTS = {
    GENERAL_CHARTER: t('generalCharter'),
    CONFORT: t('confort'),
  };

  if (!data.setups.executive) return null;
  const characteristics = mapItemsCharacteristics(
    'executive',
    data.setups.executive
  );
  const amenities = mapItemsAmenities(data, lang);
  const mapModals = data.setups.executive.mapModals;

  let props = {};
  if (characteristics && characteristics.length > 0)
    props['characteristics'] = {
      title: TEXTS.GENERAL_CHARTER,
      items: characteristics,
    };

  if (amenities && amenities.length > 0)
    props['comfort'] = {
      title: TEXTS.CONFORT,
      items: amenities,
    };

  if (mapModals && mapModals.length > 0)
    props['mapModals'] = {
      modals: mapModals,
    };

  return props;
};

const mapSetupAeromedical = (data, lang) => {
  const TEXTS = {
    GENERAL_CHARTER: t('generalCharter'),
    MEDICAL_EQUIPAMENTS: t('medicalEquipaments'),
  };

  if (!data.setups.aeromedical) return null;
  const characteristics = mapItemsCharacteristics(
    'aeromedical',
    data.setups.aeromedical
  );
  const equipments = data.setups.aeromedical.extraItems;
  const mapModals = data.setups.aeromedical.mapModals;

  let props = {};
  if (characteristics && characteristics.length > 0)
    props['characteristics'] = {
      title: TEXTS.GENERAL_CHARTER,
      items: characteristics,
    };

  if (equipments && equipments.length > 0)
    props['equipments'] = {
      title: TEXTS.MEDICAL_EQUIPAMENTS,
      items: equipments,
    };

  if (mapModals && mapModals.length > 0)
    props['mapModals'] = {
      modals: mapModals,
    };

  return props;
};

const mapSetupCargo = (data, lang) => {
  const TEXTS = {
    GENERAL_CHARTER: t('generalCharter'),
    COMPARTMENTS_TITLE: t('compartments'),
  };

  if (!data.setups.cargo) return null;
  const characteristics = mapItemsCharacteristics('cargo', data.setups.cargo);
  const compartments = data.setups.cargo.compartments;
  const mapModals = data.setups.cargo.mapModals;

  let props = {};
  if (characteristics && characteristics.length > 0)
    props['characteristics'] = {
      title: TEXTS.GENERAL_CHARTER,
      items: characteristics,
    };

  if (compartments && compartments.length > 0)
    props['compartmentsDetails'] = {
      title: TEXTS.COMPARTMENTS_TITLE,
      compartments: compartments,
      palletCompatibility: data.setups.cargo.palletCompatibilty,
      containerCompatibility: data.setups.cargo.containerCompatibility,
      numberOfSlots: data.setups.cargo.numberOfSlots,
    };

  if (mapModals && mapModals.length > 0)
    props['mapModals'] = {
      modals: mapModals,
    };

  return props;
};

const getAircraftSetupsProps = (data, lang) => {
  if (
    !data.setups ||
    !(data.setups.executive || data.setups.cargo || data.setups.aeromedical)
  )
    return null;

  return {
    executive: mapSetupExecutive(data, lang),
    aeromedical: mapSetupAeromedical(data, lang),
    cargo: mapSetupCargo(data, lang),
  };
};

const getCertification = data => {
  if (!data.certifications) return null;
  const rs = data.certifications.filter(item => {
    return item.certification.category === 'government';
  });
  if (rs.length > 0) {
    return rs[0].certification.name;
  }

  return [];
};

const mapChipsAircraftAbout = (aircraft, lang) => {
  let chips = [];

  if (
    aircraft.quantityOfPassengers
      ? aircraft.quantityOfPassengers
      : aircraft.model.quantityOfPassengers
  )
    chips.push({
      text:
        translate_to('quantityOfPassengers', lang) +
        ': ' +
        (aircraft.quantityOfPassengers
          ? aircraft.quantityOfPassengers
          : aircraft.model.quantityOfPassengers),
      svgSource:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-771 -273)"><g transform="translate(0 -549)"><g transform="translate(9 -0.276)"><g transform="translate(762 823.276)"><g transform="translate(0 0)"><g transform="translate(0 0)"><path d="M54.81,16.9a3.41,3.41,0,1,0-.845-4.754A3.414,3.414,0,0,0,54.81,16.9Z" transform="translate(-49.084 -10.689)"/><path d="M6.768,133.459,3.414,117.312H0l3.405,16.651a8.528,8.528,0,0,0,8.432,7.246H23.9v-3.414H11.829A5.121,5.121,0,0,1,6.768,133.459Z" transform="translate(0 -108.781)"/><path d="M92.9,130.986H84.57l-1.75-7c2.688,1.519,5.6,2.637,8.79,2.082v-3.627a10.5,10.5,0,0,1-8.005-2.125L80.8,118.133a3.96,3.96,0,0,0-3-.751l-.043.009a3.841,3.841,0,0,0-3.124,4.446l2.313,10.1a5.12,5.12,0,0,0,5.027,4.165H93.667l6.52,5.121,2.56-2.56Z" transform="translate(-68.748 -108.8)"/></g></g></g></g></g></g></svg>',
    });

  if (aircraft.baggageInfo ? aircraft.baggageInfo : aircraft.model.baggageInfo)
    chips.push({
      text:
        translate_to('baggage', lang) +
        ': ' +
        (aircraft.baggageInfo
          ? aircraft.baggageInfo
          : aircraft.model.baggageInfo),
      svgSource:
        '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><rect fill="none" height="24" width="24"/><g><path d="M9.5,18H8V9h1.5V18z M12.75,18h-1.5V9h1.5V18z M16,18h-1.5V9H16V18z M17,6h-2V3c0-0.55-0.45-1-1-1h-4C9.45,2,9,2.45,9,3v3 H7C5.9,6,5,6.9,5,8v11c0,1.1,0.9,2,2,2c0,0.55,0.45,1,1,1s1-0.45,1-1h6c0,0.55,0.45,1,1,1s1-0.45,1-1c1.1,0,2-0.9,2-2V8 C19,6.9,18.1,6,17,6z M10.5,3.5h3V6h-3V3.5z M17,19H7V8h10V19z"/></g></svg>',
    });

  if (aircraft.cruiseSpeed ? aircraft.cruiseSpeed : aircraft.model.cruiseSpeed)
    chips.push({
      text:
        translate_to('cruiseSpeed', lang) +
        ': ' +
        (aircraft.cruiseSpeed
          ? aircraft.cruiseSpeed
          : aircraft.model.cruiseSpeed) +
        ' km/h',
      svgSource:
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 34 34"><defs><style>.a{fill:#fff;}</style></defs><g transform="translate(-97 -418)"><g transform="translate(0 -433)"><g transform="translate(97 787.145)"><g transform="translate(0 68.855)"><path d="M19.372,73.368l1.839-4.142A24.023,24.023,0,0,0,0,75.879L3.091,78.97A19.638,19.638,0,0,1,19.372,73.368Z" transform="translate(0 -68.855)"/><path d="M96.639,189.613a15.273,15.273,0,0,0-11.3,4.451l3.091,3.091a10.819,10.819,0,0,1,6.228-3.083Z" transform="translate(-79.152 -180.858)"/><path d="M200.821,80.478a.761.761,0,0,0-.634.355l-.1.224L192.074,99.07a3.023,3.023,0,0,0-.394,1.491,3.1,3.1,0,0,0,6.128.649l.008-.054,3.779-19.905A.775.775,0,0,0,200.821,80.478Z" transform="translate(-177.794 -79.636)"/><path d="M315.307,215.338a10.86,10.86,0,0,1,1.885,1.5l3.091-3.091a15.3,15.3,0,0,0-4.119-2.921Z" transform="translate(-292.465 -200.54)"/><path d="M339.6,89.652,338.774,94a19.6,19.6,0,0,1,6.367,4.258l3.091-3.091A23.927,23.927,0,0,0,339.6,89.652Z" transform="translate(-314.232 -88.145)"/></g></g></g></g></svg>',
    });

  // if (aircraft.manufacturedAt)
  //   chips.push({
  //     text:
  //       translate_to('yearOfManufacture', lang) +
  //       ': ' +
  //       dateToYear(aircraft.manufacturedAt),
  //     svgSource:
  //       '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M7 18.333v-1.395l1.667-1.25v-4.334l-7 2.063v-1.813l7-4.146V3q0-.562.385-.948.386-.385.948-.385.562 0 .948.385.385.386.385.948v4.458l7 4.104v1.855l-7-2.063v4.334L13 16.938v1.395l-3-.854Z"/></svg>',
  //   });

  if (aircraft.refurbishedAt)
    chips.push({
      text:
        translate_to('renewalYear', lang) +
        ': ' +
        dateToYear(aircraft.refurbishedAt),
      svgSource:
        '<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M4.5 17q-.625 0-1.062-.438Q3 16.125 3 15.5v-11q0-.625.438-1.062Q3.875 3 4.5 3H11v1.5H4.5v11h11V9H17v6.5q0 .625-.438 1.062Q16.125 17 15.5 17ZM7 14v-1.5h6V14Zm0-2.25v-1.5h6v1.5ZM7 9.5V8h6v1.5Zm7-2V6h-1.5V4.5H14V3h1.5v1.5H17V6h-1.5v1.5Z"/></svg>',
    });

  return chips;
};

const getSectionsProps = (data, lang, showModelPictures) => {
  const aircraftCerts = getCertificationsGroup(data.certifications);

  const TEXTS = {
    FLIGHT_AUTONOMY: t('flightAutonomy', lang),
    AIR_CARRIER: t('airCarrier', lang),
  };

  return {
    aircraftGallery: {
      flightPage: false,
      images: showModelPictures ? data.model.pictures : data.pictures,
    },
    aircraftDescription: {
      ptitle: aircraftNameHelper(data),
      pscore: data.model.rate,
      ptext: data.model.description,
      scoreSvg: `<svg xmlns="http://www.w3.org/2000/svg" width="22.884" height="22.849" viewBox="0 0 22.884 22.849"><path fill="#00bfa5" d="M5.315,14.649,4.107,22.055a.716.716,0,0,0,1.052.741l6.284-3.473L17.725,22.8a.716.716,0,0,0,1.052-.741L17.569,14.65,22.68,9.412a.715.715,0,0,0-.4-1.206L15.242,7.131,12.088.415a.744.744,0,0,0-1.294,0L7.642,7.13.607,8.2A.715.715,0,0,0,.2,9.411Z" transform="translate(0 -0.039)"/></svg>`,
      continent:
        data?.baseSpot?.address?.city?.state?.country?.countryRegion?.name,
      company:
        data.model.producer != null
          ? {
              companySvg: data.model.producer.iconImageUrl,
              companyName: data.model.producer.name,
              country: data.model.producer.country.name,
              foundedAt: dateToYear(data.model.producer.foundedAt),
            }
          : null,
    },
    aircraftQuotation: {
      image: data.model.highlightImageUrl
        ? data.model.highlightImageUrl
        : data.highlightImageUrl,
      type: data.model.aircraftType.name,
      name: aircraftNameHelper(data),
      regulament: getCertification(data),
    },
    aircraftSetups: getAircraftSetupsProps(data, lang),
    aircraftAutonomy: {
      title: TEXTS.FLIGHT_AUTONOMY,
      reachInfo: data.maxRange || data.model.maxRange,
      chipText: data.baseSpot ? data.baseSpot.address.city.name : '',
      latitude: data.baseSpot ? data.baseSpot.address.latitude : '',
      longitude: data.baseSpot ? data.baseSpot.address.longitude : '',
      chipSvgSource: `<svg xmlns="http://www.w3.org/2000/svg" width="15.334" height="22" viewBox="0 0 15.334 22"><defs><style>.a{fill:#192a41;}</style></defs><g transform="translate(0)"><path class="a" d="M93,0a7.514,7.514,0,0,0-7.667,7.333,7.1,7.1,0,0,0,.92,3.485l6.327,10.945a.49.49,0,0,0,.839,0l6.33-10.949a7.094,7.094,0,0,0,.918-3.481A7.514,7.514,0,0,0,93,0Zm0,11a3.757,3.757,0,0,1-3.833-3.667,3.837,3.837,0,0,1,7.667,0A3.528,3.528,0,0,1,96.4,9.022,3.857,3.857,0,0,1,93,11Z" transform="translate(-85.333)"/></g></svg>`,
      mapImgSrc: `/map_autonomy.png`,
    },
    aircraftEngines: data.engine || [],
    aircraftAbout: {
      flightPage: true,
      title: 'Detalhes',
      chips: mapChipsAircraftAbout(data, lang),
      imgSrc: data.seatMapImageUrl
        ? data.seatMapImageUrl
        : data.model.seatMapImageUrl,
    },
    aircraftComfort: {
      items: data.amenities,
      flightPage: true,
    },
    aircraftSecurity: {
      flightPage: false,
      airCarrier: {
        title: TEXTS.AIR_CARRIER,
        items: [getAirCarrier(data.airCarrier, data.ownerCompany)],
      },
      airCarrierCerts: {
        items: aircraftCerts.government || null,
      },
      safetyCerts: {
        items: aircraftCerts.safety || null,
      },
      flightPermissions: {
        description: '',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#828EA090" d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" /></svg>`,
      },
    },
  };
};

const Aircraft = ({ setAircraftInfo, pageContext, location, quotation }) => {
  const [quotationOpen, toggleQuotation] = useState(false);
  const [requestToBuy, toggleRequestToBuy] = useState(false);
  const [showOnlySaleInfo, setShowOnlySaleInfo] = useState(false);
  const { search } = useLocation();
  const showModelPictures = search.includes('forSale=1');
  const [sectionsProps, setSectionsProps] = useState(
    getSectionsProps(pageContext.data, pageContext.lang, showModelPictures)
  );

  const [foldRef, foldInView] = useInView();

  const gallery = useRef(null);
  const description = useRef(null);
  const autonomy = useRef(null);
  const security = useRef(null);
  const setups = useRef(null);

  const certifications = pageContext.data.certifications;
  const { isSaleable } = pageContext.data;

  const { data } = pageContext;
  const aircraftId = data.id;

  const TEXTS = {
    FLIGHT_AUTONOMY: t('flightAutonomy'),
    TECH_SPECS: t('techSpecs'),
    SECURITY_LABEL: t('security'),
    PHOTOS_LABEL: t('photos'),
    AMENITY_TITLE: t('amenityTitle'),
    ENGINES_TITLE: t('enginesTitle'),
  };

  let navMenuProps = {
    openQuotation: () => toggleQuotation(true),
    openRequestToBuy: () => toggleRequestToBuy(true),
    visible: !foldInView,
    items: [
      {
        label: TEXTS.PHOTOS_LABEL,
        ref: gallery,
      },
      {
        label: TEXTS.TECH_SPECS,
        ref: description,
      },
      {
        label: TEXTS.FLIGHT_AUTONOMY,
        ref: autonomy,
      },
      {
        label: TEXTS.SECURITY_LABEL,
        ref: security,
      },
    ],
  };

  if (sectionsProps.aircraftSetups)
    navMenuProps.items.splice(2, 0, { label: 'Detalhes', ref: setups });

  const aircraftName = aircraftNameHelper(pageContext.data);

  const getPosition = async data => {
    const {
      coords: { latitude, longitude },
    } = data;
    if (latitude && longitude) {
      setSectionsProps({
        ...sectionsProps,
        aircraftAutonomy: {
          ...sectionsProps.aircraftAutonomy,
          latitude,
          longitude,
        },
      });
    }
  };

  const getPositionByIp = async () => {
    const {
      data: { ip },
    } = await axios.get(
      `https://api.ipstack.com/check?access_key=${IP_STACK_API}&fields=ip`
    );

    const {
      data: { longitude, latitude },
    } = await axios.get(
      `https://api.ipstack.com/${ip}?access_key=${IP_STACK_API}`
    );

    setSectionsProps({
      ...sectionsProps,
      aircraftAutonomy: {
        ...sectionsProps.aircraftAutonomy,
        latitude,
        longitude,
      },
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setShowOnlySaleInfo(params.get('forSale') == 1);
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(getPosition, getPositionByIp, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        });
      }
    }, 500);
  }, []);

  useEffect(() => {
    setSectionsProps(getSectionsProps(pageContext.data, pageContext.lang));
  }, [pageContext.lang]);

  return (
    <Layout
      dispatchedLang={location.state && location.state.dispatchLang}
      solidHeader="white"
      showNewsletter={false}
      footerStyles={{ marginBottom: '104px' }}
      pageLang={pageContext.lang}
      translations={pageContext.translations}
      useTranslationsPath={true}
    >
      <SEO
        yoast_head={'<title>' + aircraftName + ' - Flapper </title>'}
        pageLang={pageContext.lang}
        translations={pageContext.translations}
      />
      <AircraftPageOptions backButton />
      <div ref={foldRef}>
        <div ref={gallery}>
          <AircraftGallery {...sectionsProps.aircraftGallery} />
        </div>
      </div>
      {quotationOpen ? (
        <Modal
          opened={quotationOpen}
          closeModal={() => toggleQuotation(false)}
          overflowVisible
        >
          <AircraftQuotation {...sectionsProps.aircraftQuotation} />
        </Modal>
      ) : null}
      {requestToBuy ? (
        <Modal
          opened={requestToBuy}
          closeModal={() => toggleRequestToBuy(false)}
        >
          <AircraftForSaleForm aircraftId={aircraftId} />
        </Modal>
      ) : null}
      <div ref={description}>
        <AircraftDescription
          openQuotation={() => toggleQuotation(true)}
          openRequestToBuy={() => toggleRequestToBuy(true)}
          quotation={quotation ? quotation : ''}
          isSaleable={isSaleable}
          aircraftId={pageContext.data.id}
          manufacturedAt={pageContext.data.manufacturedAt}
          {...sectionsProps.aircraftDescription}
        />
      </div>

      <div
        className="container"
        style={{
          marginTop: '50px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <AircraftAbout {...sectionsProps.aircraftAbout} />
        <div
          className=""
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
          }}
        >
          {sectionsProps.aircraftComfort.items.length ? (
            <div
              style={{
                marginBottom: '30px',
              }}
            >
              <h2>{TEXTS.AMENITY_TITLE}</h2>
              <Amenities
                amenities={sectionsProps.aircraftComfort.items}
                limitToShow={99}
                showTitle={false}
                breakLine={true}
                imgSize={3}
                theme={'green'}
              />
            </div>
          ) : null}
        </div>
      </div>

      {!showOnlySaleInfo ? (
        <React.Fragment>
          <div ref={autonomy}>
            <AircraftAutonomy
              titleTag="h2"
              lang={pageContext.lang}
              {...sectionsProps.aircraftAutonomy}
            />
          </div>
          <div ref={security}>
            <AircraftSecurity
              {...sectionsProps.aircraftSecurity}
              titleTag="h2"
              certifications={certifications}
              permissions={data.countryPermissionRegions}
              short={false}
            />
          </div>
        </React.Fragment>
      ) : null}
      <NavMenu
        {...navMenuProps}
        quotation={quotation ? quotation : ''}
        isSaleable={isSaleable}
      />
    </Layout>
  );
};

Aircraft.propTypes = {
  setAircraftInfo: P.func,
  pageContext: P.object,
  location: P.object,
  quotation: P.object,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(SearchSegmentsActions, dispatch);

export default connect(null, mapDispatchToProps)(Aircraft);
