import {
  apiHost,
  getDocumentTypes,
  deleteUser,
  putUserRoute,
  registerGclidRoute,
  sendMessageWhatsappRoute,
} from '@utils/constants';
import useRequest from '@utils/useRequest';
import { removeGclidFromLS } from '@utils/gads.helper';

/**
 *
 * @param {*} param0
 * @returns
 */
const fetchDocumentType = ({ countryId, countryCode } = {}) => {
  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${getDocumentTypes}`,
      method: 'get',
      useSession: false,
      params: { countryId, countryCode },
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const deleteAccount = ({ id }) => {
  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${deleteUser}`,
      method: 'delete',
      params: { id },
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const updateUserPhoneNumber = ({ userId, phoneNumber }) => {
  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${putUserRoute}`,
      method: 'put',
      useSession: true,
      data: JSON.stringify({
        profile: {
          id: userId,
          mobileNumber: phoneNumber,
        },
      }),
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const callWhatsappSupport = ({ phoneNumber }) => {
  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${sendMessageWhatsappRoute}`,
      method: 'post',
      useSession: false,
      data: JSON.stringify({
        phone: phoneNumber,
      }),
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

const registerGclid = ({ phoneNumber, emailAddress, userId, gclid }) => {
  return new Promise((resolve, reject) => {
    useRequest({
      url: `${apiHost}${registerGclidRoute}`,
      method: 'post',
      useSession: false,
      data: JSON.stringify({
        phoneNumber: phoneNumber || null,
        emailAddress: emailAddress || null,
        userId,
        gclid,
      }),
    })
      .then(res => {
        removeGclidFromLS(true);
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export {
  fetchDocumentType,
  deleteAccount,
  updateUserPhoneNumber,
  registerGclid,
  callWhatsappSupport,
};
